module.exports = [{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-manifest@5.13.1_gatsby@5.13.7_@swc+core@1.7.11_@swc+helpers@0.4.36__babel-eslin_zukk6q4rej5qs2m3z7yfk65vlu/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Bemlo – Sammenlign hurtigt og nemt vikarbureauer inden for sundhedssektoren","short_name":"Bemlo","start_url":"/","background_color":"#0B3F87","theme_color":"#0B3F87","display":"minimal-ui","icon":"src/images/bemlo-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d610e6b0231e796e43ab743dfdbbe98e"},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-canonical-urls@5.13.1_gatsby@5.13.7_@swc+core@1.7.11_@swc+helpers@0.4.36__babel_m7w3jqofixjihl3gi54ri7fgwy/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.bemlo.dk"},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-segment-js@5.0.0_gatsby@5.13.7_@swc+core@1.7.11_@swc+helpers@0.4.36__babel-esli_7a762x5hnx2hnoocqvkld7rxwi/node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"7UBuvsHyQYqtrb3M6ELeVuYo3dbtSuRh","trackPage":true,"trackPageDelay":50,"delayLoad":false,"delayLoadTime":1000,"manualLoad":true},
    },{
      plugin: require('../../../node_modules/.pnpm/@sentry+gatsby@7.30.0_encoding@0.1.13_gatsby@5.13.7_@swc+core@1.7.11_@swc+helpers@0.4.36__bab_qpprxxv3o26ijtwgnkqkh2e5sq/node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://395007ff664b4efa913969bb08381593@o421497.ingest.sentry.io/5341379","environment":"production"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby@5.13.7_@swc+core@1.7.11_@swc+helpers@0.4.36__babel-eslint@10.1.0_eslint@8.57.1__buffer_5d5rhak3wjfda7nblf65prf5zq/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
